<template>
	<div
		class="panel"
		:class="[
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div class="key theme-inverse">
				<div class="row justify-content-center">
					<div class="col-10 col-lg-7">
						<div class="subtitle" v-if="block.key_phrase" v-html="block.key_phrase"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel'

export default {
	name: "DraftKeyPhrase1",
	mixins: [panel],
}
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/draft-keyphrase-1";
</style>
