var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",class:[
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container",class:[
			_vm.isBrand? 'sk' : '',
		]},[_c('div',{staticClass:"key theme-inverse"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-10 col-lg-7"},[(_vm.block.key_phrase)?_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.block.key_phrase)}}):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }